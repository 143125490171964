import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import validator from 'validator'
import { useNavigate } from 'react-router-dom'

import { THEME_COLORS } from '../../../ThemeConfig'
import ChipRectangle from '../../includes/chips/ChipRectangle'
import { useParams } from 'react-router-dom'
import { Context } from '../../../contexts/Store'
import ButtonTypeOne from '../../includes/buttons/ButtonTypeOne'
import { cases } from '../../../axiosConfig'

const RecommendedVideos = ({
  setActiveOption,
  activeOption,
  isVideosAdded,
  caseDetails,
}) => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { state, dispatch } = useContext(Context)
  const access_token = state.user_details.access_token
  const [videoLink, setVideoLink] = useState('')
  const [videoLinksList, setVideoLinksList] = useState([])
  const [initialVideoLinksList, setInitialVideoLinksList] = useState([])
  const [urlError, setUrlError] = useState(false)
  const [showErrors, setShowErrors] = useState(false)

  const handlePrevious = () => {
    // setActiveOption(activeOption - 1)
    dispatch({
      type: 'UPDATE_USER',
      user_details: {
        activeOption: 1,
      },
    })
  }

  const handleNext = () => {
    // if (caseDetails?.followup_exist == true) {
    dispatch({
      type: 'UPDATE_USER',
      user_details: {
        activeOption: 3,
      },
    })
    // } else {
    //   if (window.confirm('Case Report Added successfully')) {
    //     navigate('/')
    //   }
    // }
  }

  const addVideoLink = () => {
    if (videoLink !== '') {
      if (validator.isURL(videoLink)) {
        setUrlError(false)
        if (!videoLinksList.includes(videoLink)) {
          setVideoLinksList((prevLinks) => [...prevLinks, videoLink])
          setVideoLink('')
        } else {
          alert('Already exists')
        }
      } else {
        setUrlError(true)
      }
    } else {
      setUrlError(true)
    }
  }

  function detectChanges(initialValues, currentValues) {
    const isObject = (value) => typeof value === 'object' && value !== null

    const deepEqual = (obj1, obj2) => {
      if (!isObject(obj1) || !isObject(obj2)) {
        return obj1 === obj2
      }

      const keys1 = Object.keys(obj1)
      const keys2 = Object.keys(obj2)

      if (keys1.length !== keys2.length) {
        return false
      }

      return keys1.every((key) => deepEqual(obj1[key], obj2[key]))
    }
    return !deepEqual(initialValues, currentValues)
  }

  const removeVideoLink = (videoId) => {
    setVideoLinksList(videoLinksList.filter((video) => video !== videoId))
  }

  const handleFormSubmit = () => {
    var formdata = new FormData()
    formdata.append('videos', JSON.stringify(videoLinksList))
    const data = {
      videos: videoLinksList,
    }

    if (videoLinksList) {
      cases
        .put(`/add-recommended-videos/${id}/`, data, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then(function (response) {
          const { StatusCode, data } = response.data
          if (StatusCode == 6000) {
            getRecommendedVideos();
            // if (caseDetails?.followup_exist == true) {
            dispatch({
              type: 'UPDATE_USER',
              user_details: {
                activeOption: 3,
              },
            });
            // } else {
            //   if (window.confirm(data.message)) {
            //     navigate('/')
            //     dispatch({
            //       type: 'UPDATE_USER',
            //       user_details: {
            //         activeOption: 1,
            //       },
            //     })
            //   }
            // }

            setShowErrors('')
          } else {
            alert(data.message)
          }
        })
        .catch((error) => {
          console.error('Something went wrong', error)
          if (error.response.status === 404) {
            setShowErrors('NOT_FOUND')
          }
        })
    } else {
      setShowErrors('This field is required')
    }
  }

  const getRecommendedVideos = () => {
    // setLoading(true);
    let accessToken = state.user_details.access_token
    cases
      .get(`/recommended-videos/${id}/`, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data
        if (StatusCode === 6000) {
          setVideoLinksList(data.videos.map((video) => video.link))
          setInitialVideoLinksList(data.videos.map((video) => video.link))
        } else {
          // setLoading(false);
        }
      })
      .catch((error) => {
        alert(error.response.data.data.message)
      })
  }
  const handleCancel = () => {
    navigate(-1)
    dispatch({
      type: 'UPDATE_USER',
      user_details: {
        activeOption: 1,
      },
    })
  }

  useEffect(() => {
    if (isVideosAdded) {
      getRecommendedVideos()
    }
  }, [activeOption, isVideosAdded])

  useEffect(() => {
    if (showErrors !== '') {
      if (videoLink.trim() !== '') {
        if (validator.isURL(videoLink)) {
          setShowErrors('')
        } else {
          setShowErrors(true)
        }
      } else {
        const timeoutId = setTimeout(() => {
          setShowErrors('')
        }, 200)
        return () => clearTimeout(timeoutId)
      }
    }
  }, [videoLink, showErrors])

  return (
    <>
      <Container>
        <Cover>
          <FormTitle>Recommended Videos</FormTitle>
          <VideoLinks>
            {videoLinksList.map((video, index) => {
              return (
                <VideoLinkWrapper key={index}>
                  <Link to={video}>{video}</Link>
                  <CloseButton onClick={() => removeVideoLink(video)}>
                    <img
                      src={
                        require('../../../assets/image/session_summary/cancel.svg')
                          .default
                      }
                      alt="cancel"
                    />
                  </CloseButton>
                </VideoLinkWrapper>
              )
            })}
            <VideoInput>
              <input
                type="url"
                value={videoLink}
                onChange={(e) => setVideoLink(e.target.value)}
                placeholder="Paste link here"
                className={videoLink && 'active'}
              />
              {/* Add button  */}
              <ChipRectangle
                image="check_blue.svg"
                content="Add"
                bkgcolor={THEME_COLORS.chips_blue_container}
                color={THEME_COLORS.chips_blue_on_container_2}
                pd="8px 15px"
                br="8px"
                font_size="14px"
                font_weight="500"
                image_width="12.45px"
                onclick={addVideoLink}
                clickable
              />
            </VideoInput>
            {urlError && <Error className="error">Enter valid URL</Error>}
          </VideoLinks>
        </Cover>
        <RightBottom>
          <ButtonLeft>
            <ButtonTypeOne
              text="Cancel"
              color={THEME_COLORS.chips_blue_on_container_2}
              bkgcolor={THEME_COLORS.white}
              border="none"
              onclick={handleCancel}
            />
          </ButtonLeft>
          <ButtonRight>
            {activeOption !== 1 && (
              <ButtonTypeOne
                text="Previous"
                color={THEME_COLORS.dark_500}
                bkgcolor={THEME_COLORS.white}
                border={THEME_COLORS.light_400}
                onclick={handlePrevious}
              />
            )}
            <ButtonTypeOne
              text={activeOption !== 3 ? 'Next' : 'Submit'}
              ml="10px"
              color={THEME_COLORS.white}
              bkgcolor={THEME_COLORS.chips_blue_on_container_2}
              onclick={() => {
                // if (videoLinksList.length > 0) {
                if (detectChanges(initialVideoLinksList, videoLinksList)) {
                  handleFormSubmit()
                } else {
                  handleNext()
                }
                // } else {
                //     // alert("Add a video link");
                //     handleNext();
                // }
              }}
            />
          </ButtonRight>
        </RightBottom>
      </Container>
    </>
  )
}

export default RecommendedVideos
const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const Cover = styled.div``
const FormTitle = styled.h3`
  font-weight: 700;
  font-size: 16px;
  color: ${THEME_COLORS.text_title};
  margin-top: 20px;
`
const VideoLinks = styled.div`
  background-color: ${THEME_COLORS.white};
  border: 1px solid ${THEME_COLORS.light_200};
  box-shadow: ${THEME_COLORS.elevation_expert_e3};
  border-radius: 10px;
  padding: 24px;
  margin-top: 20px;
`
const VideoLinkWrapper = styled.li`
  background-color: ${THEME_COLORS.chips_green_container};
  border-radius: 8px;
  padding: 8px 16px;
  margin-bottom: 8px;
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Link = styled.a`
  color: ${THEME_COLORS.chips_green_on_container};
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
`
const CloseButton = styled.div`
  width: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  img {
    width: 100%;
  }
`
const VideoInput = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  input {
    margin-right: 16px;
    flex-grow: 1;
    background-color: ${THEME_COLORS.light_200};
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 8px 12px;
    font-weight: 400;
    font-size: 16px;
    &.active {
      background-color: ${THEME_COLORS.white};
      color: ${THEME_COLORS.text_title};
      border: 2px solid ${THEME_COLORS.chips_blue_on_container_2};
      font-weight: 500;
      font-size: 14px;
      text-decoration: underline;
    }
    &::placeholder {
      color: ${THEME_COLORS.text_para};
    }
  }
`
const Error = styled.small``
const RightBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #d3d3d3;

  @media all and (max-width: 980px) {
    margin-top: 50px;
  }
`
const ButtonLeft = styled.div``
const ButtonRight = styled.div`
  display: flex;
`
