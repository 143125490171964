import axios from "axios";

const baseURLs = {
  demo: "https://api.mindmitra.talrop.works/api/v1",
  localhost: "http://127.0.0.1:8000/api/v1", //akbar's
  live: "https://api.mymindmitra.com/api/v1",
};

const createAxiosInstance = (environment) => {
  return axios.create({
    baseURL: `${baseURLs[environment]}`,
  });
};

// localhost

// export const general = createAxiosInstance('localhost').create({
//   baseURL: `${baseURLs.localhost}/general`,
// })

// export const authentication = createAxiosInstance('localhost').create({
//   baseURL: `${baseURLs.localhost}/authentication/expert`,
// })

// export const expert = createAxiosInstance('localhost').create({
//   baseURL: `${baseURLs.localhost}/experts`,
// })

// export const patient = createAxiosInstance('localhost').create({
//   baseURL: `${baseURLs.localhost}/patients`,
// })

// export const cases = createAxiosInstance('localhost').create({
//   baseURL: `${baseURLs.localhost}/cases`,
// })

// export const socketUrl = `ws://http://127.0.0.1:8000` // websocket url local

// demo

export const general = createAxiosInstance("demo").create({
  baseURL: `${baseURLs.demo}/general`,
});

export const authentication = createAxiosInstance("demo").create({
  baseURL: `${baseURLs.demo}/authentication/expert`,
});

export const expert = createAxiosInstance("demo").create({
  baseURL: `${baseURLs.demo}/experts`,
});

export const patient = createAxiosInstance("demo").create({
  baseURL: `${baseURLs.demo}/patients`,
});

export const cases = createAxiosInstance("demo").create({
  baseURL: `${baseURLs.demo}/cases`,
});

export const socketUrl = `wss://api.mindmitra.talrop.works`; // websocket url demo

// live

// export const general = createAxiosInstance("localhost").create({
//     baseURL: `${baseURLs.live}/general`,
// });

// export const authentication = createAxiosInstance("live").create({
//     baseURL: `${baseURLs.live}/authentication/expert`,
// });

// export const expert = createAxiosInstance("live").create({
//     baseURL: `${baseURLs.live}/experts`,
// });

// export const patient = createAxiosInstance("live").create({
//     baseURL: `${baseURLs.live}/patients`,
// });

// export const cases = createAxiosInstance("live").create({
//     baseURL: `${baseURLs.live}/cases`,
// });

// export const socketUrl = `wss://api.mymindmitra.com`; // websocket url live
